
import { appStore } from '@/store'
import ErrorPage from '@/views/ErrorPage.vue'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: '404',
  computed: {
    ...mapState(appStore, ['errorState']),
  },
  components: { ErrorPage },
})
